import(/* webpackMode: "eager" */ "/home/ubuntu/actions-runner/_work/chat-saas/chat-saas/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.7.4_@playwright+test@1.47.0_babel-plugin-react-compiler@0.0.0-_dkcjdbjceeuekkmi7x7n5cqfla/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/actions-runner/_work/chat-saas/chat-saas/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.7.4_@playwright+test@1.47.0_babel-plugin-react-compiler@0.0.0-_dkcjdbjceeuekkmi7x7n5cqfla/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/actions-runner/_work/chat-saas/chat-saas/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.7.4_@playwright+test@1.47.0_babel-plugin-react-compiler@0.0.0-_dkcjdbjceeuekkmi7x7n5cqfla/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/actions-runner/_work/chat-saas/chat-saas/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.7.4_@playwright+test@1.47.0_babel-plugin-react-compiler@0.0.0-_dkcjdbjceeuekkmi7x7n5cqfla/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/actions-runner/_work/chat-saas/chat-saas/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.7.4_@playwright+test@1.47.0_babel-plugin-react-compiler@0.0.0-_dkcjdbjceeuekkmi7x7n5cqfla/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/actions-runner/_work/chat-saas/chat-saas/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.7.4_@playwright+test@1.47.0_babel-plugin-react-compiler@0.0.0-_dkcjdbjceeuekkmi7x7n5cqfla/node_modules/next/dist/client/components/render-from-template-context.js");
